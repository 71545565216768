"use client";
import { useEffect } from "react";
import { useBooleanState } from "@/hook/state";
import { AnimationGroup } from "@/animation/main";

type NavigationType = "navigate" | "reload";

const ReloadDetector = ({ children }: any) => {
  const { setRefresh, setFirstTime } = useBooleanState();

  const executeLogoAnimation = () => {
    const loader = document.querySelector(".loading");
    loader?.classList.remove("loading");

    AnimationGroup.LoadingAnimationFLIP()
      .play()
      .then(() => {
        AnimationGroup.LoaderAnimationFLIP().play();
      });
  };

  useEffect(() => {
    const isFirstVisit: boolean = !localStorage.getItem("firstVisit");

    if (isFirstVisit) {
      // First visit to the site
      localStorage.setItem("firstVisit", "true");
      const currentPath: string = window.location.pathname;

      if (currentPath === "/") {
        // If on the home page, execute the animation
        executeLogoAnimation();
      }
    } else {
      // Not the first visit
      const navigationTiming: PerformanceNavigationTiming =
        performance.getEntriesByType(
          "navigation"
        )[0] as PerformanceNavigationTiming;

      const navigationType: NavigationType =
        navigationTiming.type as NavigationType;

      if (navigationType === "reload") {
        // Navigation or refresh
        setRefresh(true);
        const loader = document.querySelector(".loading");
        loader?.classList.remove("loading");
        document.body.style.overflow = "auto";
      } else if (navigationType === "navigate") {
        // First time
        setFirstTime(true);
        const loader = document.querySelector(".loading");
        loader?.classList.remove("loading");
        document.body.style.overflow = "auto";
      }
    }
  }, [setRefresh, setFirstTime]);

  return <div className="loading page">{children}</div>;
};

export default ReloadDetector;
