import { create } from "zustand";

type State = {
  refresh: boolean;
  first_time: boolean;
  setRefresh: (value: boolean) => void;
  setFirstTime: (value: boolean) => void;
};

export const useBooleanState = create<State>((set) => ({
  refresh: false,
  first_time: false,
  setRefresh: (refresh) => set({ refresh }),
  setFirstTime: (first_time) => set({ first_time }),
}));
