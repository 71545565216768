export const handleClickMore = (e: any) => {
  const extendElement = document.querySelector(".text__extend");
  extendElement?.classList.toggle("extend");

  if (extendElement?.classList.contains("extend")) {
    e.target.classList.add("less");
    e.target.innerHTML = "X Close Expanded View";
  } else {
    e.target.classList.remove("less");
    e.target.innerHTML = "&amp; More Information";
  }
};
