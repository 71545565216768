"use client";
import { useRef, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default function ScrollSection({ children }: any) {
  const containerRef = useRef(null);

  useEffect(() => {
    let ctx = gsap.context(() => {
      let sections = gsap.utils.toArray(".panel");
      let isAnimating = false;

      function goToSection(i: any) {
        if (!isAnimating) {
          isAnimating = true;
          gsap.to(window, {
            scrollTo: {
              y: i * window.innerHeight,
              autoKill: false,
              ease: "power3.inOut",
            },
            duration: 0.45,
            onComplete: () => {
              isAnimating = false;
            },
          } as any);
        }
      }

      sections.forEach((eachPanel, i) => {
        ScrollTrigger.create({
          trigger: eachPanel,
          onEnter: () => goToSection(i),
        } as any);

        ScrollTrigger.create({
          trigger: eachPanel,
          start: "bottom bottom",
          onEnterBack: () => goToSection(i),
        } as any);
      });
    }, containerRef);

    return () => ctx.revert();
  }, []);

  return (
    <div className="scroll-section" ref={containerRef}>
      {children}
    </div>
  );
}
