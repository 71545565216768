/* eslint-disable import/no-anonymous-default-export */
import ScrollSection from "./ScrollSection";
import { handleClickMore } from "./handle";
import SectionAnimations from "./SectionAnimations";
import {
  AnimationSeeMoreIndex,
  AnimationSeeMoreNewest,
  AnimationSeeMoreServices,
  AnimationSeeMore,
  LoaderAnimationFLIP,
  LoadingAnimationFLIP,
} from "./animation";

const AnimationGroup = {
  ScrollSection,
  LoaderAnimationFLIP,
  LoadingAnimationFLIP,
  SectionAnimations,
};

export {
  AnimationGroup,
  handleClickMore,
  AnimationSeeMore,
  AnimationSeeMoreIndex,
  AnimationSeeMoreNewest,
  AnimationSeeMoreServices,
};
