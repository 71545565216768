"use client";
import { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import { defaultGSAP, splitText } from "./module";

function observerSection({ className, timeline }: any) {
  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 1,
  };

  try {
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          timeline.play();
          observer.unobserve(entry.target);
        }
      });
    }, options);

    const triggerElement = document.querySelector(`${className}`) as any;
    if (triggerElement) {
      observer.observe(triggerElement);
    } else {
      throw new Error(`Elemento con clase '${className}' no encontrado.`);
    }
  } catch (error) {
    console.error("Ha ocurrido un error:", error);
  }
}

export function NewestAnimation() {
  const tl = gsap.timeline(defaultGSAP);
  let newestTitle = splitText(".container__newest .newest__more h3");

  tl.to(".container__newest h3 .line", { overflow: "hidden" });

  tl.from(
    newestTitle.words,
    {
      opacity: 0,
      y: 100,
      stagger: 0.02,
    },
    "<"
  );

  tl.fromTo(
    ".container__newest .newest__more a",
    {
      opacity: 0,
    },
    {
      opacity: 1,
    },
    ">-0.5"
  );

  // SEEMORE

  tl.fromTo(
    ".container__newest .scroll-to",
    {
      opacity: 0,
      duration: 0.5,
    },
    {
      opacity: 1,
      duration: 0.5,
    },
    "<"
  );

  tl.fromTo(
    ".seeMore.project",
    {
      background: "#0000",
      duration: 0.5,
    },
    {
      background: "#000000cf",
      duration: 0.5,
    },
    "<"
  );

  tl.fromTo(
    [".seeMore.project .seeMore__top h3", ".seeMore.project .seeMore__top a"],
    {
      y: 20,
      opacity: 0,
      duration: 0.5,
    },
    {
      y: 0,
      opacity: 1,
      duration: 0.5,
    },
    "<"
  );

  tl.fromTo(
    ".seeMore.project .seeMore__top .lines",
    {
      opacity: 0,
      width: "0%",
    },
    {
      opacity: 1,
      width: "100%",
    },
    "<"
  );

  observerSection({
    className: ".container__newest",
    timeline: tl,
  });
}

export function ServicesAnimation() {
  const tl = gsap.timeline(defaultGSAP);
  let servicesText = splitText(".services__item ul li");

  tl.set(".services__item ul li", { overflow: "hidden" });

  tl.from(servicesText.words, {
    opacity: 0,
    stagger: 0.02,
    y: 100,
  });

  // SEEMORE

  tl.fromTo(
    ".container__services  .scroll-to",
    {
      opacity: 0,
      duration: 0.5,
    },
    {
      opacity: 1,
      duration: 0.5,
    },
    "<"
  );

  tl.fromTo(
    ".seeMore.service",
    {
      background: "#0000",
      duration: 0.5,
    },
    {
      background: "#0000001f",
      duration: 0.5,
    },
    "<"
  );

  tl.fromTo(
    [".seeMore.service .seeMore__top h3", ".seeMore.service .seeMore__top a"],
    {
      y: 20,
      opacity: 0,
      duration: 0.5,
    },
    {
      y: 0,
      opacity: 1,
      duration: 0.5,
    },
    "<"
  );

  tl.fromTo(
    ".seeMore.service .seeMore__top .lines",
    {
      opacity: 0,
      width: "0%",
    },
    {
      opacity: 1,
      width: "100%",
    },
    "<"
  );

  observerSection({
    className: ".container__services",
    timeline: tl,
  });
}

export function FooterAnimation() {
  const tl = gsap.timeline(defaultGSAP);
  let footerText = splitText(".footer__info p");
  let footerLinks = splitText(".footer__info .footer__links a");
  let footerDown = splitText(".footer .footer__down .social a");
  let footerNewsletter = splitText(".footer .btn-default");

  tl.set(
    [
      ".footer__info p .line",
      ".footer__info .footer__links a",
      ".footer  .footer__down .social a",
      ".footer  .footer__down .btn-default",
    ],
    {
      overflow: "hidden",
    }
  );

  tl.fromTo(
    footerText.words,
    {
      opacity: 0,
      y: 100,
    },
    {
      opacity: 1,
      stagger: 0.02,
      duration: 0.5,
      y: 0,

      onComplete: () => {
        footerText.revert();
      },
    },
    "<"
  );

  tl.fromTo(
    [".footer__info button", ".footer #logo", ".footer .footer__info"],
    {
      opacity: 0,
    },
    {
      opacity: 1,
    },
    ">-1"
  );

  tl.fromTo(
    footerNewsletter.words,
    {
      opacity: 0,
      y: 20,
    },
    {
      opacity: 1,
      stagger: 0.02,
      duration: 0.5,
      y: 0,

      onComplete: () => {
        footerNewsletter.revert();
      },
    },
    ">"
  );

  tl.fromTo(
    footerLinks.words,
    {
      y: 100,
      opacity: 0,
    },
    {
      stagger: 0.02,
      y: 0,
      opacity: 1,
      onComplete: () => {
        footerLinks.revert();
      },
    },
    "<"
  );

  tl.fromTo(
    footerDown.words,
    {
      y: 100,
      opacity: 0,
    },
    {
      y: 0,
      opacity: 1,
      onComplete: () => {
        footerDown.revert();
      },
    },
    ">-1"
  );

  observerSection({
    className: ".container__footer",
    timeline: tl,
  });
}

export function extFooterAnimation() {
  const tl = gsap.timeline(defaultGSAP);
  let footerText = splitText(".footer__info p");
  let footerLinks = splitText(".footer__info .footer__links a");
  let footerDown = splitText(".footer .footer__down .social a");
  let footerNewsletter = splitText(".footer .btn-default");

  tl.set(
    [
      ".footer__info p .line",
      ".footer__info .footer__links a",
      ".footer  .footer__down .social a",
      ".footer  .footer__down .btn-default",
    ],
    {
      overflow: "hidden",
    }
  );

  tl.fromTo(
    footerText.words,
    {
      opacity: 0,
      y: 100,
    },
    {
      opacity: 1,
      stagger: 0.02,
      duration: 0.5,
      y: 0,

      onComplete: () => {
        footerText.revert();
      },
    },
    "<"
  );

  tl.fromTo(
    [".footer__info button", ".footer #logo", ".footer .footer__info"],
    {
      opacity: 0,
    },
    {
      opacity: 1,
    },
    ">-1"
  );

  tl.fromTo(
    footerNewsletter.words,
    {
      opacity: 0,
      y: 20,
    },
    {
      opacity: 1,
      stagger: 0.02,
      duration: 0.5,
      y: 0,

      onComplete: () => {
        footerNewsletter.revert();
      },
    },
    ">"
  );

  tl.fromTo(
    footerLinks.words,
    {
      y: 100,
      opacity: 0,
    },
    {
      stagger: 0.02,
      y: 0,
      opacity: 1,
      onComplete: () => {
        footerLinks.revert();
      },
    },
    "<"
  );

  tl.fromTo(
    footerDown.words,
    {
      y: 100,
      opacity: 0,
    },
    {
      y: 0,
      opacity: 1,
      onComplete: () => {
        footerDown.revert();
      },
    },
    ">-1"
  );

  return tl;
}

export default function SectionAnimations({ children }: any) {
  const containerRef = useRef(null);

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {}, containerRef);
    FooterAnimation();
    return () => ctx.revert();
  }, []);

  return (
    <div className="container" ref={containerRef}>
      {children}
    </div>
  );
}
